<template>
  <div class="container-top" v-if="detail">
    <div class="pc">
      <div class="container padding-top-60">
        <div class="product-wrapper">
          <!-- 상품정보 좌 -->
          <div>
            <div class="card-img" :style="imgField(detail.img, 'pc')"></div>
            <div class="flex-align padding-24" v-if="detail.preview_url">
              <div @click="showMobilePreview">
                <button class="button flex-align preview-box">
                  <img src="/static/icon/u_mobile-android-alt.svg" alt="" class="unselect"
                      style="width:20px;height:20px">
                  <span class="body2-medium margin-left-12">모바일 미리보기</span>
                </button>
              </div>
              <a :href="detail.preview_url" target="_blank">
                <button class="button flex-align preview-box margin-left-20">
                  <img src="/static/icon/fi_monitor.svg" alt="" class="unselect"
                       style="width:20px;height:20px">
                  <span class="body2-medium margin-left-12">데스크톱 미리보기</span>
                </button>
              </a>
            </div>
          </div>
          <!-- 상세정보 우 -->
          <div>
            <!--<span class="flex-align body5 sub3">
                &lt;!&ndash;<span>솔루션</span>
                <img style="width:15px;height:15px" class="svg-sub5" src="/static/icon/fi_chevron-right.svg">&ndash;&gt;
                <span class="unselect" @click="routerPush('/store')">{{ detail.category1_name }}</span>
                <img style="width:15px;height:15px" class="svg-sub5" src="/static/icon/fi_chevron-right.svg">
                <span class="unselect" @click="routerPush(`/store?tab=${detail.category2}`)">{{ detail.category2_name }}</span>
            </span>-->
            <!-- 파트너 -->
            <div class="flex-align" v-if="detail.partner">
              <img style="width:32px;height:32px"
                   :src="detail.partner.img" v-if="detail.partner.img">
              <div class="body4 sub3 margin-left-8">{{ detail.partner.name }}</div>
            </div>
            <div class="subtitle3 main padding-top-8">{{ detail.title }}</div>
            <div class="body3 padding-top-8">{{ detail.content }}</div>
            <div class="body5 primary padding-top-16" v-if="detail.category2===42">*서비스 적용시, 발생하는 용역에 대해 추가 과금이 청구될 수 있습니다.</div>
            <div v-else-if="detail.category2===45"></div>
            <div class="body5 primary padding-top-16 flex-align store-detail-guide-text" v-else>
              <div class="text-underline unselect" @click="$refs.guideModal.open()">나에게 맞는 서비스 개설하는 법(이용가이드)</div>
              <img src="/static/icon/fi_chevron-right.svg" style="width:16px;height:16px" class="svg-primary">
            </div>

            <product-option-selector :product="detail"></product-option-selector>
          </div>
        </div>
        <!-- 상품 상세 -->
        <!-- 탭 -->
        <tab :menu="[{id:0,name:'서비스 안내'},{id:1,name:'이용문의'}]" style="margin-top:100px">
          <template v-slot:tab0>
            <div v-if="detail.imgs && detail.imgs.length>0" style="margin-top:64px;padding:0 75px">
              <div class="content-slide" v-if="detail.imgs">
                <slick ref="slick" :options="slickOptions">
                  <div v-for="(img, idx) in detail.imgs"
                       :key="'slide-'+idx" class="slick-img-outer">
                    <img :src="img" alt="" class="slick-img" :style="slickImg">
                  </div>

                </slick>
              </div>
            </div>
            <div class="sun-editor-editable content column is-12" id="anchor0" v-html="detail.info">{{ detail.info }}</div>
          </template>
          <template v-slot:tab1>
            <div class="content column is-12" style="margin-top:64px">
              <product-inquiry></product-inquiry>
            </div>
          </template>
        </tab>
      </div>
    </div>

    <div class="mobile">
      <div class="card-img" :style="imgField(detail.img, 'mobile')"></div>
      <div class="container padding-top-16">
        <!-- 파트너 -->
        <div class="flex-align" v-if="detail.partner">
          <img style="width:24px;height:24px"
               :src="detail.partner.img" v-if="detail.partner.img">
          <div class="body5 sub3 margin-left-4">{{ detail.partner.name }}</div>
        </div>
        <div class="subtitle5 main" style="margin-top:6px">{{ detail.title }}</div>
        <div class="padding-top-8 sub2">{{ detail.content }}</div>
        <div class="body5 primary padding-top-16" v-if="detail.category2===42">*서비스 적용시, 발생하는 용역에 대해 추가 과금이 청구될 수 있습니다.</div>
        <div v-else-if="detail.category2===45"></div>
        <div class="body5 primary padding-top-16 flex-align" v-else>
          <div class="text-underline" @click="$refs.guideModal.open()">나에게 맞는 서비스 개설하는 법(이용가이드)</div>
          <img src="/static/icon/fi_chevron-right.svg" style="width:16px;height:16px" class="svg-primary">
        </div>
        <div class="tablet-preview">
          <div class="grid" style="grid-template-columns:repeat(2, 1fr);gap:24px">
            <a :href="detail.preview_url" target="_blank" v-if="detail.preview_url">
              <button class="button is-primary-light2 margin-top-24"
                      style="width:100%;height:40px">모바일 미리보기</button>
            </a>
            <a :href="detail.preview_url" target="_blank" v-if="detail.preview_url">
              <button class="button is-primary-light2 margin-top-24"
                      style="width:100%;height:40px">데스크톱 미리보기</button>
            </a>
          </div>
        </div>
        <a class="mobile-preview" :href="detail.preview_url" target="_blank" v-if="detail.preview_url">
          <button class="button is-primary-light2 margin-top-24"
                  style="width:100%;height:40px">미리보기</button>
        </a>
      </div>
      <div class="lp-divider-gray3 padding-top-24"></div>
      <div class="container padding-top-16">
        <product-option-selector :product="detail"></product-option-selector>
      </div>
      <!-- 상품 상세-->
      <div>
        <div class="lp-divider-gray3 margin-top-12"></div>
        <!-- 탭 -->
        <tab :menu="[{id:0,name:'서비스 안내'},{id:1,name:'이용문의'}]">
          <template v-slot:tab0>
            <div v-if="detail.imgs && detail.imgs.length>0" class="content column is-12">
              <div class="content-slide" v-if="detail.imgs">
                <scroll-wrapper>
                  <div class="flex-align">
                    <template v-for="(img, idx) in detail.imgs">
                      <img :src="img" alt="" class="slick-img" style="width:260px;height:385px;margin:0 6px" :key="'slide-'+idx">
                    </template>
                  </div>
                </scroll-wrapper>
                <!--<slick ref="slick" :options="slickOptions">
                  <div v-for="(img, idx) in detail.imgs"
                       :key="'slide-'+idx" class="slick-img-outer">
                    <img :src="img" alt="" class="slick-img" :style="slickImg">
                  </div>
                </slick>-->
              </div>
            </div>
            <div v-html="detail.info" class="sun-editor-editable padding-left-16 padding-right-16">{{ detail.info }}</div>
          </template>
          <template v-slot:tab1>
            <product-inquiry></product-inquiry>
          </template>
        </tab>
      </div>
    </div>
    <div class="store-detail-guide-modal">
      <sweet-modal overlay-theme="dark" ref="guideModal" :enable-mobile-fullscreen="false">
        <div class="pc">
          <div class="flex-align">
            <div class="circle-primary-light">
              <img src="/static/icon/fi_book-open.svg" class="svg-primary" style="width:48px;height:48px">
            </div>
            <div style="margin-left:16px;text-align: left">
              <div class="h5 main" style="margin-bottom:6px">디자인 마켓 이용 방법</div>
              <div class="body4 sub2">디자인 마켓을 통해 나에게 맞는 서비스를 개설해보세요!</div>
            </div>
          </div>
          <div class="lp-divider-gray1" style="margin: 24px 0 32px 0"></div>
          <div class="guide-modal-content">
            <div v-for="(item,idx) in guide" :Key="'guide-'+idx">
              <div class="subtitle5 main">{{ `${idx+1}.${item.title}` }}</div>
              <div class="body4 sub2" style="padding-bottom:40px">{{ item.content }}</div>
            </div>
          </div>
        </div>
        <div class="mobile">
          <div class="flex-align">
            <div class="circle-primary-light">
              <img src="/static/icon/fi_book-open.svg" class="svg-primary">
            </div>
            <div style="margin-left:16px;text-align: left">
              <div class="h8 main" style="margin-bottom:4px">디자인 마켓 이용 방법</div>
              <div class="body6 sub2">나에게 맞는 서비스를 개설해보세요!</div>
            </div>
          </div>
          <div class="lp-divider-gray1" style="margin: 24px 0 32px 0"></div>
          <div class="guide-modal-content">
            <div v-for="(item,idx) in guide" :Key="'guide-'+idx">
              <div class="subtitle5 main">{{ `${idx+1}.${item.title}` }}</div>
              <div class="body4 sub2" :style="`padding-bottom:40px`">{{ item.content }}</div>
            </div>
          </div>
        </div>
      </sweet-modal>
    </div>
  </div>

</template>

<script>
  import Slick from 'vue-slick'
  import UserAPIMixin from "../../mixins/UserAPIMixin";
  import ProductOptionSelector from "../component/ProductOptionSelector";
  import ProductInquiry from "./ProductInquiry";
  import { SweetModal } from 'sweet-modal-vue';
  import Tab from "../module/Tab";
  import ScrollWrapper from "../module/ScrollWrapper";

  export default {
    name: "StoreDetail",
    mixins: [
      UserAPIMixin
    ],
    components: {
      ScrollWrapper,
      Tab,
      ProductInquiry,
      ProductOptionSelector,
      Slick,
      SweetModal
    },
    data() {
      return {
        // 상세 상품
        detail: undefined,
        slickOptions: {
          centerMode: false,
          variableWidth: false,
          infinite: true,
          autoplay: false,
          centerPadding:'20px',
          slidesToShow: 3,
          slidesToScroll: 1,
          speed: 600,
          prevArrow: '<div class="arrow-left"><img class="arrow-img" src="/static/icon/fi_chevron-left.svg" @click="prev"></div>',
          nextArrow: '<div class="arrow-right"><img class="arrow-img" src="/static/icon/fi_chevron-right.svg" @click="next"></div>',
          responsive: [
            {
              breakpoint: 767,
              settings: {
                autoplay: true,
                slidesToShow: 1,
                centerMode: true,
                dots: false,
                arrows: false
              }
            }
          ]
        },
        guide: [
          {
            title: '디자인 스킨 선택',
            content: '디자인 마켓에서 내 주문전환 방식에 맞는 디자인 스킨을 선택합니다.'
          },
          {
            title: '옵션 선택하기',
            content: '나의 사업 모델에 적합한 상품 옵션을 선택합니다. '
          },
          {
            title: '부가 서비스 선택 및 결제',
            content: '선택한 테마의 결제 방식과 부가서비스 이용 여부를 선택하고 결제합니다. 디자인 스킨 수정을 선택하지 않은 고객은 STEP 4,5를 건너뛰셔도 됩니다. '
          },
          {
            title: '담당자 배치와 수정 의견서 작성 (수정 옵션 선택 시)',
            content: '‘디자인 스킨 + 부분 수정 개발 요청’ 옵션을 선택한 고객의 경우, 결제 후 서비스 신청이 접수되면 담당자가 배치됩니다. 담당자가 제시하는 가이드에 따라 수정 의견서를 작성합니다. '
          },
          {
            title: '디자인 컨펌 (수정 옵션 선택 시)',
            content: '수정 제안서의 디자인, 개발 요소를 검토합니다. '
          },
          {
            title: '개발 진행',
            content: '검수가 완료된 디자인 시안을 바탕으로 개발에 착수합니다. '
          },
          {
            title: '내 서비스 신청 완료!',
            content: '런치팩과의 커뮤니케이션으로 지속적인 업데이트가 가능합니다. '
          }
        ]
      }
    },
    watch:{
      '$route'(n){
        if(n.query.id) {
          this.getProductDetail();
        }
      }
    },
    created() {
      if(this.$route.query.id) {
        this.getProductDetail();

        if(this.isMobile) {
          this.slickOptions.variableWidth = false;
          this.slickOptions.centerMode = false;
          this.slickOptions.autoplay = true;
        }
      }
    },
    beforeDestroy() {
      if(this.$route.path.indexOf('app_market') === -1) {
        document.getElementById('ch-plugin').style.display = '';
      }
    },
    mounted () {
      if(this.$route.path.indexOf('app_market') === -1) {
        setTimeout(() => {
          document.getElementById('ch-plugin').style.display = 'none';
        }, 1000);
      }
    },
    computed: {
      slickImg() {
        if(window.innerWidth>500) {
          let width = 318;
          let height = 483;
          let margin = '0 6px';
          return {
            width: width+'px',
            height: height+'px',
            margin: margin,
            borderRadius: '8px'
          }
        } else {
          let width = window.innerWidth - 24.5;
          let height = width * 1.31;
          let padding = '0 6px';
          return {
            width: width+'px',
            height: height+'px',
            padding: padding,
            borderRadius: '8px'
          }
        }
      },
      contentSlideStyle() {
        if(window.innerWidth<500) {
          return {
            width: '100%',
            height: this.slickImg.height
          }
        } else {
          return {
            width: '1106px',
            height: this.slickImg.height
          }
        }
      }
    },
    methods: {
      showMobilePreview() {
        window.name = 'preview_parent';
        let popup = window.open('', 'previewPopup', 'width=360, height=640, top=300, left=300, fullscreen=no, menubar=no, status=no, toolbar=no, titlebar=yes, location=yes, scrollbar=no');
        popup.location.href = this.detail.preview_url+'/home';
      },
      preview(device) {
        console.log(device)
        location.href = this.detail.preview_url;
      },
      next() {
        this.$refs.slick.next();
      },

      prev() {
        this.$refs.slick.prev();
      },
      // 상품 상세
      getProductDetail() {
        this.$axios.get(`public/launchpack/v1/theme/${this.$route.query.id}`).then(async res=>{
          if(res.status === 200) {
            res.data.img = res.data.imgs[0];
            // 대표이미지 제외
            res.data.imgs = res.data.imgs.splice(1);
            // 슬라이드쇼 반복을 위해서 concat
            // res.data.imgs = res.data.imgs.concat(res.data.imgs);
            let detail = res.data;
            if(!detail.service_use_price) {
              detail.service_use_price = {
                id: 1,
                name: "서비스 운영 이용료",
                desc: null,
                price: 300000
              }
            }
            await this.setServiceUsePrice(detail);
            this.detail = detail;
          }
        });
      },
      async setServiceUsePrice(detail) {
        let pack_name = '';
        let item = this.findItem(detail.params, 'name', '주문전환');
        if(item) {
          if(item.value_select && item.value_select.length>0) {
            let pack_item = this.findItem(item.choice_items, 'id', item.value_select[0]);
            if(pack_item) {
              pack_name = pack_item.name;
            }
          }
          let query = '';
          if(pack_name) {
            query = `?pack=${this.convertPack(pack_name)}`
          }
          await this.$axios.get(`public/launchpack/service_use_price/info${query}`).then(res=>{
            detail.service_use_price = res.data;
          })
        }
      },
      convertPack(name) {
        switch (name) {
          case '': return '';
          case '전화연결': return 'call';
          case '아웃링크': return 'outlink';
          case '상품배송주문': return 'delivery';
          case '모임신청': return 'meet';
          case '상담신청': return 'counsel';
          case '견적요청': return 'estimate';
          case '사용권': return 'license';
          case '컨텐츠다운로드주문': return 'commerce';
          case '숙박예약': return 'booking';
          case '서비스예약': return 'reservation';
        }
      },
      imgField(img, device) {
        let style = {
          backgroundImage: `url(${this.image(img)})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center'
        };
        if(device==='mobile') {
           style.height = (window.innerWidth * 0.7).toString() +'px';
        }
        return style;
      }
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'
  .pc
    .product-wrapper
      display grid
      grid-gap 0 72px
      grid-template-columns repeat(2, 1fr)

    .card-img
      width 516px
      height 516px
      border-radius 8px

  .mobile
    .card-img
      width 100%
      border 1px solid $gray3


  .writeInquire
    text-align left
    margin-bottom 10px
    color #303030
    font-weight 500

  .writeTxt
    width 100%
    height 96px

  .writeTxt::-ms-input-placeholder
    text-align left

  .writeTxt::-webkit-input-placeholder
    text-align left

  .writeTxt::-moz-input-placeholder
    text-align left

  .preview-box
    width 216px
    height 48px
    border 1px solid $sub5
    color $sub2

  .preview-box:hover
    background-color $gray4

  .lp-divider-gray3
    border-bottom 12px solid $gray3

  .circle-primary-light
    width 88px
    height 88px
    background-color $primary-light2
    border-radius 100%
    display flex
    align-items center
    justify-content center
    img
      width 48px
      height 48px

  .guide-modal-content
    padding-right 22px
    height 454px
    overflow-y auto
    text-align left
    white-space pre-line
    word-break keep-all

  @media (max-width:1024px)
    .circle-primary-light
      width 44px
      height 44px
      img
        width 24px
        height 24px
    .guide-modal-content
      padding-right 8px
      height 387px

</style>

<style lang="stylus">
  @import '~assets/css/lp_main'
  .store-detail-guide-text:hover
    color $primary-dark

  .store-detail-guide-modal
    .sweet-modal.is-alert .sweet-content
      padding 40px 50px

  @media (max-width:1024px)
    .store-detail-guide-modal
      .sweet-modal.is-alert .sweet-content
        padding 48px 16px 24px 16px !important
    .tablet-preview
      display block
    .mobile-preview
      display none
  @media (max-width:640px)
    .tablet-preview
      display none
    .mobile-preview
      display block
</style>
